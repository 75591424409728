import React from 'react';

const IconAngular = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="96px" height="96px">
    <path fill="#bdbdbd" d="M23.933 2L3 9.285 6.308 36.408 23.955 46 41.693 36.278 45 9.156z" />
    <path fill="#b71c1c" d="M42.818 10.527L24 4.135 24 43.695 39.832 35.017z" />
    <path
      fill="#dd2c00"
      d="M23.941 4.115L5.181 10.644 8.168 35.143 23.951 43.721 24 43.695 24 4.135z"
    />
    <path fill="#bdbdbd" d="M24 5.996L24 15.504 32.578 34 36.987 34z" />
    <path fill="#eee" d="M11.013 34L15.422 34 24 15.504 24 5.996z" />
    <path fill="#bdbdbd" d="M24 24H30V28H24z" />
    <path fill="#eee" d="M18 24H24V28H18z" />
  </svg>
);

export default IconAngular;
