import React from 'react';

const IconElasticSearch = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="96px" height="96px">
    <path
      fill="#29b6f6"
      d="M37,24c0-3.314-2.686-6-6-6H5.918C5.323,19.894,5,21.909,5,24s0.323,4.106,0.918,6H31 C34.314,30,37,27.314,37,24z"
    />
    <path
      fill="#4db6ac"
      d="M25,44c6.524,0,12.305-3.133,15.954-7.968C38.835,34.151,36.055,33,33,33H7.157 C10.453,39.52,17.196,44,25,44z"
    />
    <path
      fill="#ffca28"
      d="M25,4C17.195,4,10.453,8.48,7.157,15H33c3.051,0,5.828-1.148,7.946-3.024 C37.299,7.138,31.525,4,25,4z"
    />
    <path
      fill="#f9a825"
      d="M22.073,15c-1.513-3.367-3.845-6.288-6.747-8.501C11.817,8.443,8.957,11.412,7.143,15H22.073z"
    />
    <path
      fill="#009688"
      d="M15.326,41.501c2.902-2.213,5.233-5.134,6.747-8.501H7.143 C8.957,36.588,11.817,39.557,15.326,41.501z"
    />
    <path
      fill="#212121"
      d="M5.928,18C5.332,19.895,5,21.907,5,24s0.332,4.105,0.928,6h17.234C23.703,28.092,24,26.081,24,24 s-0.297-4.092-0.837-6H5.928z"
    />
  </svg>
);
export default IconElasticSearch;
